import utils from "./utils";
/*-----------------------------------------------
|                     Isotope
-----------------------------------------------*/

const isotopeInit = () => {

  const Selector = {
    MASONRY_ITEM: '.masonry-item',
    DATA_MASONRY: '[data-masonry]',
    DATA_FILTER: '[data-filter]',
    DATA_FILER_NAV: '[data-filter-NAV]',
  }

  const DATA_KEY = {
    MASONRY: 'masonry'
  }
  const ClassName = {
    ACTIVE: 'active'
  }

  if (window.Isotope) {

    const masonryItems = document.querySelectorAll(Selector.DATA_MASONRY);
    masonryItems.length && masonryItems.forEach((masonryItem) => {
      window.imagesLoaded(masonryItem, ()=>{

        masonryItem.querySelectorAll('.masonry-item').forEach( item => {
          // eslint-disable-next-line
          item.style.visibility='visible';
        });

        const userOptions = utils.getData(masonryItem, DATA_KEY.MASONRY);
        const defaultOptions = { 
          itemSelector: Selector.MASONRY_ITEM,
          layoutMode: 'packery',
        };
        const options = window._.merge(defaultOptions, userOptions);
        const isotope = new window.Isotope(masonryItem, options);
        

        //--------- filter -----------------
        const filterElement = document.querySelector(Selector.DATA_FILER_NAV); 
        filterElement?.addEventListener('click', function(e){
          const item = e.target.dataset.filter;   
          isotope.arrange({ filter: item });
          document.querySelectorAll(Selector.DATA_FILTER).forEach(el =>{
            el.classList.remove(ClassName.ACTIVE);  
          })
          e.target.classList.add(ClassName.ACTIVE);
        })
        //---------- filter end ------------
        
        return isotope;
      })
    });
  }
};

export default isotopeInit;
